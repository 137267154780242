import { useEffect } from "react";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const OurInformation = () => {
  useEffect(() => {
    window.location.assign(
      "https://www.sanathan.com/static/media/Materiality%20Policy%20and%20related%20information.127183c3.pdf"
    );
  }, []);

  return (
    <MetaDecorator
      noIndex
      canonicalLink={window.location.href}
      metaTitle="Materiality Policy | Sanathan Textiles"
      metaDesc="This document has been formulated to define the materiality policy for identification of outstanding material litigation involving Sanathan Textiles Limited, its Directors, its Promoters and its Subsidiary"
    />
  );
};

export default OurInformation;
