import { useEffect } from "react";
import { FirstCertificate, secondCertificate } from "../../images";
import "./Popcertificate.scss";
import "./Popcertificate.css";

const PopCert = ({ onRequestClose }) => {
  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        console.log("close clicked");
        onRequestClose();
      }
    }

    // Prevent scolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });
  return (
    <>
      <section className="popup_form_wrapper12">
        <div className="products_popup12">
          <div className="img_and_close_button">
            <figure>
              <img src={FirstCertificate} alt="sanathan certificate image" className="cert_img_1" />
            </figure>
            <figure>
              <img src={secondCertificate} alt="sanathan certificate image" />
            </figure>
            <button type="button" onClick={onRequestClose}>
              <span aria-hidden="true" z>
                &times;
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default PopCert;
