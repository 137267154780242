import { useEffect } from "react";
import "./Thankyou.css";

import { thankyouMessage } from "../../images";

const Thankyou = ({ onRequestClose }) => {
    useEffect(() => {
        function onKeyDown(event) {
            if (event.keyCode === 27) {
                // Close the modal when the Escape key is pressed
                console.log("close clicked")
                onRequestClose();
            }
        }

        // Prevent scolling
        document.body.style.overflow = "hidden";
        document.addEventListener("keydown", onKeyDown);

        // Clear things up when unmounting this component
        return () => {
            document.body.style.overflow = "visible";
            document.removeEventListener("keydown", onKeyDown);
        };
    });

    return (
        <>
            <section className="popup_form_wrapper">
                <div className="thankyou_popup">
                    <div className="close_button">
                    <button type="button" onClick={onRequestClose} className='btn-close'>
                            <span class="icon-cross"></span>
                            {/* <span class="visually-hidden">Close</span> */}
                            {/* <span aria-hidden="true">&times;</span> */}
                        </button>
                    </div>
                    <figure>
                        <img src={thankyouMessage} alt="sanathan thankyou image"/>
                    </figure>
                    <span className="thankyou_message">Thank you</span>
                    <p>Your message has been received, we will update you shortly.</p>
                </div>
            </section>
        </>
    );
}

export default Thankyou;