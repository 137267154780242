import { useEffect } from "react";
import "./AboutUsOurJourney.css";

import Dash from "../../components/Dash/Dash";

import { StaticImage } from "../../images";

//swiper imports
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import { Link } from "react-router-dom";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
]);

const AboutUsOurJourney = () => {
  // var years = ['1943', '1977', '1993', '2005'];
  useEffect(() => {
    window.scrollTo(0, 0);
    // var aboutus_our_journey_content_slider_wrapper = new Swiper(
    //     ".swiper-container.aboutus_our_journey_content_slider_wrapper",
    //     {
    //         slidesPerView: 1,
    //         simulateTouch: false
    //     }
    // );

    var aboutus_our_journey_year_slider_wrapper = new Swiper(
      ".swiper-container.aboutus_our_journey_year_slider_wrapper",
      {
        slidesPerView: 1,
        centeredSlides: true,
        // autoplay: true,
        allowTouchMove: false,
        navigation: {
          nextEl: ".aboutus_our_journey_slider .navigations .right_arrow",
          prevEl: ".aboutus_our_journey_slider .navigations .left_arrow",
        },
        breakpoints: {
          768: {
            slidesPerView: 4,
            spaceBetween: 36,
          },
          375: {
            slidesPerView: 1,
            spaceBetween: 36,
          },
        },
      }
    );

    var aboutus_our_journey_description_slider_wrapper = new Swiper(
      ".swiper-container.aboutus_our_journey_description_slider_wrapper",
      {
        slidesPerView: 1,
        autoplay: true,
      }
    );

    // aboutus_our_journey_year_slider_wrapper.controller.control = aboutus_our_journey_content_slider_wrapper;
    // aboutus_our_journey_content_slider_wrapper.controller.control = aboutus_our_journey_year_slider_wrapper;
    aboutus_our_journey_year_slider_wrapper.controller.control =
      aboutus_our_journey_description_slider_wrapper;
    aboutus_our_journey_description_slider_wrapper.controller.control =
      aboutus_our_journey_year_slider_wrapper;
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="About Us - Our Journey | Sanathan Textiles"
        metaDesc="Sanathan Textiles is promoted and managed by the Dattani family having more than 100+ years of experience in the yarn industry."
      />

      <section className="aboutus_our_journey" data-aos="fade-in">
        <div className="wrapper">
          <div class="breadcrumb_styles">About us {">"} Our Journey</div>
          <h1 className="hollow_title">Our Journey</h1>
          <div className="dash_wrapper">
            <Dash />
          </div>
          <div className="aboutus_our_journey_heading">
            <h3>100+ Years Of Cumulative Promoter Experience At All Places</h3>
            <p>
              Sanathan Textiles is promoted and managed by the Dattani family
              having more than 100+ years of experience in the yarn industry.
              Incorporated in 2005, Sanathan Textiles enjoys a rich legacy and
              history passed on by the promoters. And that journey of growth and
              progress continues till date.
            </p>
          </div>
        </div>
      </section>
      <section className="aboutus_our_journey_slider">
        <div className="wrapper">
          {/* <figure data-aos="fade-up">
                        <img src={StaticImage} alt="sanathan img" />
                    </figure> */}
          <div className="aboutus_our_journey_content_slider_wrapper swiper-container">
            <ul className="swiper-wrapper">
              <li className="swiper-slide">
                <div className="hollow_title">How we got here</div>
              </li>
              <li className="swiper-slide">
                <div className="hollow_title">How we got here</div>
              </li>
              <li className="swiper-slide">
                <div className="hollow_title">How we got here</div>
              </li>
              <li className="swiper-slide">
                <div className="hollow_title">How we got here</div>
              </li>
            </ul>
          </div>
          <div className="aboutus_our_journey_year_slider_wrapper swiper-container">
            <ul className="swiper-wrapper">
              <li className="swiper-slide">
                <span className="custom_pagination">2005</span>
              </li>
              <li className="swiper-slide">
                <span className="custom_pagination">2006</span>
              </li>
              <li className="swiper-slide">
                <span className="custom_pagination">2007</span>
              </li>
              <li className="swiper-slide">
                <span className="custom_pagination">2008</span>
              </li>
              <li className="swiper-slide">
                <span className="custom_pagination">2011</span>
              </li>
              <li className="swiper-slide">
                <span className="custom_pagination">2013</span>
              </li>
              <li className="swiper-slide">
                <span className="custom_pagination">2014</span>
              </li>
              <li className="swiper-slide">
                <span className="custom_pagination">2015</span>
              </li>
              <li className="swiper-slide">
                <span className="custom_pagination">2017</span>
              </li>
              <li className="swiper-slide">
                <span className="custom_pagination">2018</span>
              </li>
              <li className="swiper-slide">
                <span className="custom_pagination">2019</span>
              </li>
              <li className="swiper-slide">
                <span className="custom_pagination">2020</span>
              </li>
              <li className="swiper-slide">
                <span className="custom_pagination">2021</span>
              </li>
              <li className="swiper-slide">
                <span className="custom_pagination">2024</span>
              </li>
            </ul>
          </div>
          <div className="aboutus_our_journey_description_slider_wrapper swiper-container">
            <ul className="swiper-wrapper">
              <li className="swiper-slide">
                <p className="aboutus_our_journey_slider_content">
                  Sanathan Textiles was incorporated saled up manufacturing
                  operations.
                </p>
              </li>
              <li className="swiper-slide">
                <p className="aboutus_our_journey_slider_content">
                  Company took over the business of manufacturing polyester yarn
                  from Sanathan Texturisers with capacity of 4,500 MTPA.
                </p>
              </li>
              <li className="swiper-slide">
                <p className="aboutus_our_journey_slider_content">
                  Our Company commenced cotton yarn manufacturing at our
                  facility located at Silvassa with a capacity of 1,500 MTPA.
                </p>
              </li>
              <li className="swiper-slide">
                <p className="aboutus_our_journey_slider_content">
                  Achieved installed manufacturing capacity of polyester yarn at
                  13,550 MTPA, tripled from 4,500 MTPA in the year 2004 by
                  gradual expansion.
                </p>
              </li>
              <li className="swiper-slide">
                <p className="aboutus_our_journey_slider_content">
                  Undertook expansion for doubling the manufacturing capacities
                  of cotton yarn from 1,500 MTPA to 3,500 MTPA.
                </p>
              </li>
              <li className="swiper-slide">
                <p className="aboutus_our_journey_slider_content">
                  Setting up of a fully integrated plant for polyester yarn and
                  achieved the installed manufacturing capacity of 46,500 MTPA
                  tripled from 13,550 MTPA in the year 2008 by gradual expansion
                </p>
              </li>
              <li className="swiper-slide">
                <p className="aboutus_our_journey_slider_content">
                  Undertook expansion for almost doubling the manufacturing
                  capacity of polyester yarn from 46,500 MTPA to 92,950 MTPA
                </p>
              </li>
              <li className="swiper-slide">
                <p className="aboutus_our_journey_slider_content">
                  Undertook expansion for increasing the manufacturing
                  capacities of cotton yarn from 3,500 MTPA to 6,200 MTPA.
                </p>
              </li>
              <li className="swiper-slide">
                <p className="aboutus_our_journey_slider_content">
                  Undertook an expansion of value-added product basket and
                  installation of upgraded machinery for polyester and cotton
                  yarn of partially oriented yarn and fully oriented yarn.
                </p>
              </li>
              <li className="swiper-slide">
                <p className="aboutus_our_journey_slider_content">
                  Setting-up of plant for manufacturing of yarns for industrial
                  and technical textile with a capacity of 5,475 MTPA
                </p>
              </li>
              <li className="swiper-slide">
                <p className="aboutus_our_journey_slider_content">
                  Installed manufacturing capacity of polyester yarn reached
                  capacity of 200,750 MTPA, almost doubled from 92,950 MTPA in
                  the year 2014 by gradual expansion.
                </p>
              </li>
              <li className="swiper-slide">
                <p className="aboutus_our_journey_slider_content">
                  More than doubled the capacity of cotton yarn manufacturing
                  from 6,200 MTPA in the year 2019 to 14,000 MTPA.
                </p>
              </li>
              <li className="swiper-slide">
                <p className="aboutus_our_journey_slider_content">
                  IDY capacity expansion up to 9000 MTPA
                </p>
              </li>
              <li className="swiper-slide">
                <p className="aboutus_our_journey_slider_content">
                  Subsidiary commences production of Double Twisted Cotton Yarn
                  at Silvassa Unit 3 with production capacity of 540 MTPA.
                </p>
              </li>
            </ul>
          </div>
          <div className="navigations">
            <button className="left_arrow">Previous</button>
            <button className="right_arrow">Next</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsOurJourney;
