import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  facebook,
  gallery1,
  moreblog1,
  moreblog2,
  moreblog3,
  playicon,
  whatsapp,
  twitterblog,
  copy,
  blog6BannerInner,
} from "../../images";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Link } from "react-router-dom";
//utils
import { reduceDescSize } from "../../helpers/utils";

import "./BlogInner6.scss";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const BlogInner6 = () => {
  const { width } = useWindowDimensions();
  const [copied, setCopied] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const blogDateArr = [
    {
      date: "June 2, 2022",
      category: "Lorem Ipsum",
      company: "Sanathan",
    },
  ];

  const blogDescArr = [
    {
      id: 1,
      heading:
        "Types of Yarn at Sanathan Textiles: Everything you need to know",
      desc1: `
         Sanathan Textiles is a beacon in the textile business, where innovation meets tradition, providing a wide selection of yarns to meet customer specific demands. Sanathan Textiles has something for everyone, whether you work in fashion, technical textiles, or want to find sustainable solutions. Here's a closer look at the types of  yarns we offer refined to your unique requirements.
         <br /><br />
         <strong>Polyester Filament Yarn: The Versatile Workhorse</strong><br />
         The wide choice of polyester yarns at <a href="https://www.sanathan.com/">Sanathan Textiles</a> is second to none. Our state-of-the-art continuous polymerization equipment and triple lustre options allow us to meet and exceed specific requirements for wide range yarn products. These polyester yarns are appreciated for their high tensile strength, durability, and adaptability, which make them suitable for various end uses and applications.
         <br /><br />
         Whether everyday garments, home textiles, or specialised technical uses, our polyester yarns deliver inimitable performance and reliability. Choose Sanathan Textiles for the finest <a href="https://www.sanathan.com/products/polyester-yarn">polyester yarns</a>, designed to elevate your products to new heights of excellence. At Sanathan Textiles, you’ll find superior polyester yarns suited for active wear, home textiles, automotive textiles and geo textiles.
         <br /><br />
         <strong>Yarns for Technical Textiles: Engineered for Excellence</strong><br />
         In 2017, Sanathan Textiles expanded its operations into yarns for <a href="https://www.sanathan.com/products/yarn-for-technical-textile">technical textiles</a>, introducing high-tensile strength yarns tailored for industrial and technical applications. These fibres are designed to satisfy the severe requirements of diverse specialised sectors, providing exceptional durability and strength in harsh situations. From automotive textiles to geotextiles and beyond, our high-tenacity yarns provide dependable performance where it is most needed.
         <br /><br />
         <strong>Sanathan Reviro: A Greener Tomorrow</strong><br />
         At Sanathan Textiles, environmental sustainability drives our innovation. Our range of <a href="https://www.sanathan.com/products/reviro">Sanathan Reviro</a> yarns highlights the commitment to recycling waste materials into high-quality yarns, significantly reducing landfill waste and lowering pollution. This process not only saves resources but also converts waste into valuable products.  Our goal is to set a standard for environmentally responsible practices in the business, inspiring others to follow suit and create a more sustainable future for future generations.
         <br /><br />
         <strong>Born Dyed: Colourful Innovation</strong><br />
         Our range of <a href="https://www.sanathan.com/products/born-dyed">dyed polyester yarns</a>, Born & Dyed, is breaking the barriers of dyeing. Using dope-dyed technology, the colours are added during polymerization thereby resulting in an exceptionally bright and durable colour.
         <br /><br />
         This procedure not only improves the aesthetics of the yarns, but it also makes them more environmentally friendly, as it uses substantially less water and chemicals than standard dying methods.
         <br /><br />
         For instance, fashion or home textiles that require artistic beauty can be provided with Born & Dyed yarns, which are less hazardous to their surroundings while still maintaining their authentic quality. Our varied range of colours caters to diverse customer requirements, ensuring that we meet specific aesthetic preferences and design needs.
         <br /><br />
         <strong>Sanathan Drycool: Performance Meets Comfort</strong><br />
         The <a href="https://www.sanathan.com/products/dry-cool">Sanathan Drycool yarns</a> are what you have been looking for if you need fabrics that enhance performance. Lightweight, breathable and durable fabrics made out of Drycool yarns are best suited for sportswear, athleisure, clothing, innerwear and more.
         <br /><br />
         The advanced moisture-wicking capabilities of these dry cool yarns keep the wearer comfortable during physical activity, ensuring they remain cool dry. Whether for professional athletes or casual gym-goers, these Drycool yarns offer maximum utility without compromising on comfort.
         <br /><br />
         <strong>Puro Cotton Yarns: Excellence in Every Thread</strong><br />
         We focus on producing fine-count compact yarns by sourcing premium cotton from India and around the globe. These yarns are spun to perfection, ensuring top-notch quality and performance for diverse textile applications. At Sanathan Textiles, our Silvassa facility boasts an installed capacity of 223,750 MTPA across three yarn verticals. Our range of '<a href="https://www.sanathan.com/products/cotton-yarn">Puro Cotton Yarns</a>' is crafted for a variety of uses, including apparel, suiting, shirting, bedsheets, and home textiles.
         <br /><br />
         <strong>Sanathan Stretch Yarn:</strong><br />
         At Sanathan Textiles, our Stretch Yarns exemplify the fusion of flexibility and comfort. Designed for dynamic applications, these yarns incorporate advanced elastic fibres that offer superior stretch and recovery. Ideal for garments that demand a perfect fit and ease of movement, our Stretch Yarns are perfect for activewear, swimwear, and casual apparel.
         <br /><br />
         The inherent elasticity of our Stretch Yarns ensures that fabrics maintain their shape and provide exceptional comfort, even with prolonged wear. Whether it's for everyday clothing or specialised applications, Sanathan's Stretch Yarns deliver a blend of durability and flexibility, meeting the needs of modern, active lifestyles.
         <br /><br />
         <strong>Why Choose Sanathan Textiles?</strong><br />
         The fabric business needs companies such as Sanathan Textiles that are dedicated to providing nothing less than quality, sustainable solutions to their customers. Our yarn portfolio has been carefully thought out, taking into account what’s important for customers who want to develop fabrics that are out of the ordinary. By selecting Sanathan Textiles, you gain access to:
         <br /><br />
         <strong>Cutting-edge technology:</strong><br />
         Our continuous polymerization setup and advanced manufacturing techniques, combined with high-level automation and adherence to international quality standards, ensure the production of the highest quality yarns.
         <br /><br />
         <strong>Sustainability:</strong><br />
         Our sustainability efforts, such as in-house solar power generation, the use of recycled packing materials, and eco-friendly initiatives like Sanathan Reviro and dope-dyed technology, further reduce our environmental impact.
         <br /><br />
         <strong>Versatility:</strong><br />
         From every day polyester yarns to specialized high-tenacity yarns and performance-enhancing Drycool yarns, our range has something for every industry, sector and application.
         <br /><br />
         <strong>Customization: </strong><br />
         We work closely with our clients to develop yarns that meet their specific needs, ensuring satisfaction and success in their respective sectors.
         <br /><br />
         Sanathan Textiles offers a diverse range of yarns adapted to the textile industry's various requirements. Our commitment to quality, innovation, and sustainability guarantees that our clients receive the best resources for making remarkable textiles. Explore Textiles and learn how we  can help your products and business reach new heights.
         <br /><br />
         Ready to elevate your products and business? Explore our range of yarn products today and find the ideal yarn solutions to bring your vision to life.
        `,
    },
  ];

  const moreblogsArr = [
    {
      img: moreblog1,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog2,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog3,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog2,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
  ];

  const galleryArr = [
    {
      id: 1,
      type: "video",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 2,
      type: "image",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 3,
      type: "video",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 4,
      type: "image",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
  ];

  useEffect(() => {
    var gallery_swiper_container = new Swiper(
      ".swiper-container.gallery_swiper_container",
      {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          prevEl: ".bloginner_sec2 .left_arrow",
          nextEl: ".bloginner_sec2 .right_arrow",
        },
        breakpoints: {
          767: {
            slidesPerView: 1.6,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 2.7,
            spaceBetween: 10,
          },
        },
        // loop: true,
        // centeredSlides: true,
        // loopedSlides: thumbArr.length, //how many slides it should duplicate
      }
    );
    var moreblogs_swiper_container = new Swiper(
      ".swiper-container.moreblogs_swiper_container",
      {
        slidesPerView: 3,
        spaceBetween: 40,
        loop: false,
        navigation: {
          prevEl: ".bloginner_sec3 .left_arrow",
          nextEl: ".bloginner_sec3 .right_arrow",
        },
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          767: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      }
    );
  });

  useEffect(() => {
    if (copied) {
      let copiedTimeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => {
        clearTimeout(copiedTimeout);
      };
    }
  }, [copied]);

  const dateList = blogDateArr.map((item) => (
    <>
      <p className="blog_date">{item.date} | </p>
      <p className="blog_category"> {item.category} </p>
      <p className="blog_company">| {item.company}</p>
    </>
  ));

  const descList = blogDescArr.map((item) => (
    <>
      <p
        className="blog_desc"
        dangerouslySetInnerHTML={{ __html: item.desc1 }}
      />
    </>
  ));

  const galleryVideoImageList = galleryArr.length
    ? galleryArr.map((item, i) => (
        <div className="swiper-slide" key={i}>
          {item.type == "video" ? (
            <>
              <div
                className="video_wrapper_div"
                onClick={() => handleImageWrapperClick(item.ytLink)}
              >
                <div className="content">
                  <img
                    src={item.image}
                    alt="Sanathan video gallery img"
                    loading="lazy"
                    className="gallery_img"
                  />
                  <div class="content-overlay"></div>
                  <img
                    src={playicon}
                    alt="Sanathan coloredArrow img"
                    loading="lazy"
                    className="playicon"
                    onClick={() => handleImageWrapperClick(item.ytLink)}
                  />
                  {/* <h2 className="galleryImageName">{item.title}</h2> */}
                </div>

                {/* <h2 className="galleryImageName">{item.title}</h2> */}
              </div>
            </>
          ) : (
            <>
              <div className="content">
                <img
                  src={item.image}
                  alt="Sanathan blog gallery img"
                  loading="lazy"
                  className="gallery_img"
                />
                <div class="content-overlay"></div>
                {/* <h2 className="galleryImageName">{item.title}</h2> */}
              </div>
            </>
          )}
        </div>
      ))
    : null;

  const moreblogsList = moreblogsArr.map((item, i) => (
    <>
      <li className="swiper-slide moreblog_detail_wrapper">
        <Link to="/blog-inner" key={i}>
          <div className="imageanimate zoom-out">
            <img
              src={item.img}
              alt="Sanathan our blog img"
              loading="lazy"
              className="moreblog_img"
            />
            {/* <div class="content-overlay"></div> */}
          </div>
          <div className="text_container">
            <h1 className="moreblog_date">{item.date}</h1>
            <div className="verticle_line"> | </div>
            <h1 className="moreblog_category"> {item.category}</h1>
            <h1 className="moreblog_name">{item.title}</h1>
            <p className="moreblog_synopsis">
              {reduceDescSize(item.desc, 100)}
            </p>
            <div className="moreblog_cta_wrapper"></div>
          </div>
        </Link>
      </li>
    </>
  ));

  const handleImageWrapperClick = (videoLink) => {
    setToggler(!toggler);
    setVidLink(videoLink);
  };

  const copyUrl = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="Types of Yarns at Sanathan Textiles: Quality, Innovation, and Sustainability"
        metaDesc="Learn about the different types of yarns available at Sanathan Textiles, including polyester, cotton, technical textiles, and sustainable solutions. Find the perfect yarn for your business needs."
      />
      <section className="bloginner_sec1" data-aos="fade-in">
        <div className="wrapper">
          <img
            className="mainimg"
            src={blog6BannerInner}
            alt="Sanathan blog inner img"
          />
          <div className="row social_icon_date">
            <div className="col-md-6 col-lg-4 datecat_list">
              {/* {dateList} */}
            </div>
            <div className="col-md-1 col-lg-4"></div>
            <div className="col-md-5 col-lg-4">
              {width < 767 ? <h2 className="share_title">Share</h2> : null}
              <div className="social_main_wrapper">
                <ul className="social_icons">
                  {width > 767 ? (
                    <li className="social_icon_li">
                      <h2 className="share_title">Share</h2>
                    </li>
                  ) : null}
                  <li className="social_icon_li">
                    <FacebookShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={facebook}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </FacebookShareButton>
                  </li>
                  <li className="social_icon_li">
                    <WhatsappShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={whatsapp}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </WhatsappShareButton>
                  </li>
                  <li className="social_icon_li">
                    <TwitterShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={twitterblog}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </TwitterShareButton>
                  </li>
                  <li
                    className="social_icon_li social_icon_li_copy"
                    onClick={copyUrl}
                  >
                    <span className="social_icon">
                      <img
                        src={copy}
                        alt="Sanathan copy url logo"
                        loading="lazy"
                      />
                    </span>
                    <span className="social_name">
                      {!copied ? "" : "Copied!"}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="horizontal_line"></div>
          <h1 className="blog_inner_name">
            Types of Yarn at Sanathan Textiles: Everything you need to know
          </h1>
          <div>{descList}</div>
        </div>
      </section>
    </>
  );
};

export default BlogInner6;
