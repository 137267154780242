import "./Dash.css";

const Dash = (props) => {
    return (
        <span
            className="dash"
            style={
                props.white
                    ? { backgroundColor: "#FFFFFF" }
                    : { backgroundColor: "#002B5A" }
            }
        ></span>
    );
};

export default Dash;
