import { Switch, Route, Redirect } from "react-router-dom";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import Home from "./pages/Home/Home";
import ProductListing from "./pages/ProductListing/ProductListing";
import AboutUsTheCompany from "./pages/AboutUsTheCompany/AboutUsTheCompany";
import AboutUsOurJourney from "./pages/AboutUsOurJourney/AboutUsOurJourney";
import AboutUsManagement from "./pages/AboutUsManagement/AboutUsManagement";
import OurSetupInfrastructure from "./pages/OurSetupInfrastructure/OurSetupInfrastructure";
import OurSetupTechnology from "./pages/OurSetupTechnology/OurSetupTechnology";
import OurSetupCertifications from "./pages/OurSetupCertifications/OurSetupCertifications";
import OurSetupQualityAndRnD from "./pages/OurSetupQualityAndRnD/OurSetupQualityAndRnD";
import Newsroom from "./pages/Newsroom/Newsroom";
import OurSales from "./pages/OurSales/OurSales";
import InvestorRelations from "./pages/InvestorRelations/InvestorRelations";
import ESG from "./pages/ESG/ESG";
import ProductsInner from "./pages/ProductsInner/ProductsInner";
import ProductsPopup from "./components/ProductsPopup/ProductsPopup";
import { useEffect, useState } from "react";
import Loader from "./components/Loader/Loader";
import PolyesterYarn from "./pages/ProductsInner/Polyester/Polyester";
import TechnicalYarn from "./pages/ProductsInner/Technical/Technical";
import Revir from "./pages/ProductsInner/Revir/Revir";
import Borndyed from "./pages/ProductsInner/Borndyed/Borndyed";
import Functional from "./pages/ProductsInner/Functional/Functional";
import Customised from "./pages/ProductsInner/Customised/Customised";
import Ouryarn from "./pages/OurYarnsLanding/Ouryarnslanding";
import Careers from "./components/Careers/Careers";
import OurInformation from "./pages/OurInformation/OurInformation";
import ContactUs from "./pages/ContactUs/ContactUs";
import Blogs from "./pages/Blog/Blog";
import BlogInner from "./pages/BlogInner/BlogInner";
import BlogInner2 from "./pages/BlogInner2/BlogInner2";
import BlogInner3 from "./pages/BlogInner3/BlogInner3";
import BlogInner4 from "./pages/BlogInner4/BlogInner4";
import BlogInner5 from "./pages/BlogInner5/BlogInner5";
import BlogInner6 from "./pages/BlogInner6/BlogInner6";
import BlogInner7 from "./pages/BlogInner7/BlogInner7";
import DryCool from "./pages/ProductsInner/DryCool/DryCool";
import BlogInner8 from "./pages/BlogInner8/BlogInner8";

const Routing = () => {
  const [isLoading, setLoading] = useState(true);

  function fakeRequest() {
    return new Promise((resolve) => setTimeout(() => resolve(), 0));
  }

  useEffect(() => {
    fakeRequest().then(() => {
      const el = document.querySelector("#root");
      if (el) {
        //   el.remove();
        setLoading(!isLoading);
      }
    });
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/products" component={ProductListing} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route
          exact
          path="/about-us/the-company"
          component={AboutUsTheCompany}
        />
        <Route
          exact
          path="/about-us/our-journey"
          component={AboutUsOurJourney}
        />
        <Route exact path="/about-us/our-team" component={AboutUsManagement} />
        <Route
          exact
          path="/our-setup/infrastructure"
          component={OurSetupInfrastructure}
        />
        <Route
          exact
          path="/our-setup/tech-in-textile"
          component={OurSetupTechnology}
        />
        <Route
          exact
          path="/our-setup/certifications"
          component={OurSetupCertifications}
        />
        <Route
          exact
          path="/our-setup/quality-and-r+d"
          component={OurSetupQualityAndRnD}
        />
        <Route exact path="/ouryarn-in-your-life" component={Ouryarn} />
        <Route exact path="/newsroom" component={Newsroom} />
        <Route exact path="/our-sales" component={OurSales} />
        <Route exact path="/investor-relations" component={InvestorRelations} />
        <Route exact path="/esg" component={ESG} />
        {/* <Route exact path="/thankyou" component={Thankyou} /> */}
        <Route exact path="/products/cotton-yarn" component={ProductsInner} />
        <Route
          exact
          path="/products/polyester-yarn"
          component={PolyesterYarn}
        />
        <Route
          exact
          path="/products/yarn-for-technical-textile"
          component={TechnicalYarn}
        />
        <Route exact path="/products/reviro" component={Revir} />
        <Route exact path="/products/born-dyed" component={Borndyed} />
        <Route
          exact
          path="/products/dry-cool"
          component={DryCool}
        />
        {/* <Route exact path="/products/functional-yarn" component={Functional} /> */}
        {/* <Route exact path="/products/customised-yarn" component={Customised} /> */}

        {/* <Route exact path="/products/popup" component={ProductsPopup} /> */}
        <Route exact path="/blogs" component={Blogs} />
        <Route exact path="/blogs/recycled-pet-chip" component={BlogInner} />
        <Route
          exact
          path="/blogs/development-yarn-fibers"
          component={BlogInner2}
        />
        <Route exact path="/blogs/technical-textiles" component={BlogInner3} />
        <Route exact path="/blogs/re-weaving-indias-success-story" component={BlogInner4} />

        <Route exact path="/blogs/polyester-yarn-market-in-india" component={BlogInner5} />
        <Route exact path="/blogs/types-of-yarn" component={BlogInner6} />
        <Route exact path="/blogs/technical-textile-innovations" component={BlogInner7} />
        <Route exact path="/blogs/craft-your-festive-wardrobe-with-sanathan-textile-yarns-for-blazers-suits-kurtas-and-sarees" component={BlogInner8} />

        <Route exact path="/career" component={Careers} />
        <Route exact path="/Other-Information.php" component={OurInformation} />
        <Redirect to="/"></Redirect>
      </Switch>
      <Footer />
    </>
  );
};

export default Routing;
