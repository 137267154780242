import React, { useEffect } from "react";
import "./ouryarnslanding.scss";

import {
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
} from "../../images";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import { Link } from "react-router-dom";

const Ouryarn = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/ouryarn-in-your-life"
        metaTitle="One Yarn Many Things | Sanathan Textiles"
        metaDesc="We manufacture Yarns which are used in our daily lives everyday! Our Yarns have reached the hands of millions worldwide in the form of endless products."
      />

      <section className="ouryarn_sec1" data-aos="fade-in">
        <div className="wrapper">
          <div className="left_section" data-aos="fade-up">
            <h1 className="heading">
              One Yarn <br />
              Many Things
            </h1>
          </div>
        </div>
      </section>

      <section className="ouryarn_sec2" data-aos="fade-in">
        <div className="product_inner_sec2" data-aos="fade-up">
          <div className="productinner_detail_wrapper">
            <div className="productinner_container">
              <p>
                We are{" "}
                <Link to="/" className="new_link">
                  polyester manufacturers in India, manufacturing Yarns
                </Link>{" "}
                which are used in our daily lives every day, everywhere!
                Sanathan Yarns have reached the hands of millions worldwide in
                the form of products such as apparel, home furnishings, luggage,
                ropes, marine nets, geogrid fabrics, seatbelts, car upholstery,
                sportswear, lingerie and innerwear, umbrellas, personal
                protective equipment, firefighting equipment, pipes, optical
                cables, all made from our yarns and the list is endless!
              </p>
            </div>

            <div className="productinner_container">
              <div className="row m-0">
                <div className="col-md-4 col-lg-4 p-0 order-lg-3">
                  <img
                    src={image2}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img"
                  />
                </div>
                <div className="col-md-4 col-lg-4 p-0 order-lg-2">
                  <img
                    src={image1}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2"
                  />
                </div>
                <div className="col-md-4 col-lg-4 p-0 order-lg-1">
                  <div className="content_cont brown_container">
                    <div className="heading">Automotive Application</div>
                    <div className="productinner_heading_desc">
                      <ul>
                        <li>Seat belts</li>
                        <li>Upholstery and Carpet</li>
                        <li>Airbags</li>
                        <li>Filters</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="productinner_container">
              <div className="row m-0">
                <div className="col-md-4 col-lg-4 p-0 order-lg-1">
                  <img
                    src={image3}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img_alternate"
                  />
                </div>
                <div className="col-md-4 col-lg-4 p-0 order-lg-2">
                  <img
                    src={image4}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2_alternate"
                  />
                </div>
                <div className="col-md-4 col-lg-4 p-0 order-lg-3">
                  <div className="content_cont brown_container">
                    <div className="heading">Medical & Healthcare</div>
                    <div className="productinner_heading_desc">
                      <ul>
                        <li>Protective masks</li>
                        <li>PPE kits</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="productinner_container">
              <div className="row m-0">
                <div className="col-lg-4 p-0 order-lg-3">
                  <img
                    src={image6}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img"
                  />
                </div>
                <div className="col-lg-4 p-0 order-lg-2">
                  <img
                    src={image5}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2"
                  />
                </div>
                <div className="col-lg-4 p-0 order-lg-1">
                  <div className="content_cont brown_container">
                    <div className="heading">Technical Textiles</div>
                    <div className="productinner_heading_desc">
                      <ul>
                        <li>Geogrid fabric</li>
                        <li>Conveyor belts</li>
                        <li>Awnings, Canopy & Outdoor fabrics</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="productinner_container">
              <div className="row m-0">
                <div className="col-lg-4 p-0 order-lg-1">
                  <img
                    src={image8}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img_alternate"
                  />
                </div>
                <div className="col-lg-4 p-0 order-lg-2">
                  <img
                    src={image7}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2_alternate"
                  />
                </div>
                <div className="col-lg-4 p-0 order-lg-3">
                  <div className="content_cont brown_container">
                    <div className="heading">Best of Fabrics</div>
                    <div className="productinner_heading_desc">
                      <ul>
                        <li>Making of shirting</li>
                        <li>Suiting</li>
                        <li>Home furnishings</li>
                        <li>Upholstery</li>
                        <li>Garments</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="productinner_container">
              <div className="row m-0">
                <div className="col-lg-4 p-0 order-lg-3">
                  <img
                    src={image10}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img"
                  />
                </div>
                <div className="col-lg-4 p-0 order-lg-2">
                  <img
                    src={image9}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2"
                  />
                </div>
                <div className="col-lg-4 p-0 order-lg-1">
                  <div className="content_cont brown_container">
                    <div className="heading">Sports & Athleisure</div>
                    <div className="productinner_heading_desc">
                      <ul>
                        <li>Sports nets</li>
                        <li>Parachutes</li>
                        <li>Artificial grass</li>
                        <li>Turfs</li>
                        <li>Hot Air balloons</li>
                        <li>Sleeping bags</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="productinner_container">
              <div className="row m-0">
                <div className="col-lg-4 p-0 order-lg-1">
                  <img
                    src={image11}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img_alternate"
                  />
                </div>
                <div className="col-lg-4 p-0 order-lg-2">
                  <img
                    src={image12}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2_alternate"
                  />
                </div>
                <div className="col-lg-4 p-0 order-lg-3">
                  <div className="content_cont brown_container">
                    <div className="heading">Active Wear & Inner Wear</div>
                    <div className="productinner_heading_desc">
                      <ul>
                        <li>Leggings</li>
                        <li>Swimsuits</li>
                        <li>Tapes</li>
                        <li>Rainwear</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="productinner_container">
              <div className="row m-0">
                <div className="col-lg-4 p-0 order-lg-3">
                  <img
                    src={image14}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img"
                  />
                </div>
                <div className="col-lg-4 p-0 order-lg-2">
                  <img
                    src={image13}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2"
                  />
                </div>
                <div className="col-lg-4 p-0 order-lg-1">
                  <div className="content_cont brown_container">
                    <div className="heading">Home Textiles</div>
                    <div className="productinner_heading_desc">
                      <ul>
                        <li>Curtains fabric</li>
                        <li>Sofa Upholstery</li>
                        <li>Carpets</li>
                        <li>Bedsheets</li>
                        <li>Duvet</li>
                        <li>Pillows & Cushions</li>
                        <li>Towels & Bathmats</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="productinner_container">
              <div className="row m-0">
                <div className="col-lg-4 p-0 order-lg-1">
                  <img
                    src={image15}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img_alternate"
                  />
                </div>
                <div className="col-lg-4 p-0 order-lg-2">
                  <img
                    src={image16}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2_alternate"
                  />
                </div>
                <div className="col-lg-4 p-0 order-lg-3">
                  <div className="content_cont brown_container">
                    <div className="heading">Travel & Leisure</div>
                    <div className="productinner_heading_desc">
                      <ul>
                        <li>Suitcase</li>
                        <li>Bags</li>
                        <li>Umbrella</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="productinner_container">
              <div className="row m-0">
                <div className="col-lg-4 p-0 order-lg-3">
                  <img
                    src={image18}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img"
                  />
                </div>
                <div className="col-lg-4 p-0 order-lg-2">
                  <img
                    src={image17}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2"
                  />
                </div>
                <div className="col-lg-4 p-0 order-lg-1">
                  <div className="content_cont brown_container">
                    <div className="heading">Specialised Application</div>
                    <div className="productinner_heading_desc">
                      <ul>
                        <li>Bullet proof jackets</li>
                        <li>Fireproof Jackets</li>
                        <li>High Altitude Combat Gear</li>
                        <li>Space Applications</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ouryarn_sec3" data-aos="fade-in">
        <div className="product_inner_sec2" data-aos="fade-up">
          <div className="productinner_detail_wrapper">
            <div className="productinner_container text-center">
              <p className="mobile_desc">
                We manufacture Yarns which are used in our daily lives every
                day, everywhere! Sanathan Yarns have reached the hands of
                millions worldwide in the form of products such as apparel, home
                furnishings, luggage, ropes, marine nets, geogrid fabrics,
                seatbelts, car upholstery, sportswear, lingerie and innerwear,
                umbrellas, personal protective equipment, firefighting
                equipment, pipes, optical cables, all made from our yarns and
                the list is endless!
              </p>
            </div>
            <div className="productinner_container">
              <div className="content_cont brown_container">
                <div className="heading">Automotive Application</div>
                <div className="productinner_heading_desc">
                  <ul>
                    <li>Seat belts</li>
                    <li>Upholstery and Carpet</li>
                    <li>Airbags</li>
                    <li>Filters</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-6 p-0 order-2">
                  <img
                    src={image2}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img"
                  />
                </div>
                <div className="col-6 col-lg-4 p-0 order-1">
                  <img
                    src={image1}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2"
                  />
                </div>
              </div>
            </div>

            <div className="productinner_container">
              <div className="content_cont brown_container">
                <div className="heading">Medical & Healthcare</div>
                <div className="productinner_heading_desc">
                  <ul>
                    <li>Protective masks</li>
                    <li>PPE kits</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-6 p-0 order-2">
                  <img
                    src={image3}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img"
                  />
                </div>
                <div className="col-6 col-lg-4 p-0 order-1">
                  <img
                    src={image4}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2"
                  />
                </div>
              </div>
            </div>

            <div className="productinner_container">
              <div className="content_cont brown_container">
                <div className="heading">Technical Textiles</div>
                <div className="productinner_heading_desc">
                  <ul>
                    <li>Geogrid fabric</li>
                    <li>Conveyor belts</li>
                    <li>Awnings, Canopy & Outdoor fabrics</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-6 p-0 order-2">
                  <img
                    src={image6}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img"
                  />
                </div>
                <div className="col-6 col-lg-4 p-0 order-1">
                  <img
                    src={image5}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2"
                  />
                </div>
              </div>
            </div>

            <div className="productinner_container">
              <div className="content_cont brown_container">
                <div className="heading">Best of Fabrics</div>
                <div className="productinner_heading_desc">
                  <ul>
                    <li>Making of shirting</li>
                    <li>Suiting</li>
                    <li>Home furnishings</li>
                    <li>Upholstery</li>
                    <li>Garments</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-6 p-0 order-2">
                  <img
                    src={image8}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img"
                  />
                </div>
                <div className="col-6 col-lg-4 p-0 order-1">
                  <img
                    src={image7}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2"
                  />
                </div>
              </div>
            </div>

            <div className="productinner_container">
              <div className="content_cont brown_container">
                <div className="heading">Sports & Athleisure</div>
                <div className="productinner_heading_desc">
                  <ul>
                    <li>Sports nets</li>
                    <li>Parachutes</li>
                    <li>Artificial grass</li>
                    <li>Turfs</li>
                    <li>Hot Air balloons</li>
                    <li>Sleeping bags</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-6 p-0 order-2">
                  <img
                    src={image10}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img"
                  />
                </div>
                <div className="col-6 col-lg-4 p-0 order-1">
                  <img
                    src={image9}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2"
                  />
                </div>
              </div>
            </div>

            <div className="productinner_container">
              <div className="content_cont brown_container">
                <div className="heading">Active Wear & Inner Wear</div>
                <div className="productinner_heading_desc">
                  <ul>
                    <li>Leggings</li>
                    <li>Swimsuits</li>
                    <li>Tapes</li>
                    <li>Rainwear</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-6 p-0 order-2">
                  <img
                    src={image11}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img"
                  />
                </div>
                <div className="col-6 col-lg-4 p-0 order-1">
                  <img
                    src={image12}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2"
                  />
                </div>
              </div>
            </div>

            {/* <div className="productinner_container">
              <div className="content_cont brown_container">
                <div className="heading">Home Textiles</div>
                <div className="productinner_heading_desc">
                  <ul>
                    <li>Curtains fabric</li>
                    <li>Sofa Upholstery</li>
                    <li>Carpets</li>
                    <li>Bedsheets</li>
                    <li>Duvet</li>
                    <li>Pillows & Cushions</li>
                    <li>Towels & Bathmats</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-6 p-0 order-2">
                  <img
                    src={image14}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img"
                  />
                </div>
                <div className="col-6 col-lg-4 p-0 order-1">
                  <img
                    src={image13}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2"
                  />
                </div>
              </div>
            </div> */}

            <div className="productinner_container">
              <div className="content_cont brown_container">
                <div className="heading">Travel & Leisure</div>
                <div className="productinner_heading_desc">
                  <ul>
                    <li>Suitcase</li>
                    <li>Bags</li>
                    <li>Umbrella</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-6 p-0 order-2">
                  <img
                    src={image15}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img"
                  />
                </div>
                <div className="col-6 col-lg-4 p-0 order-1">
                  <img
                    src={image16}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2"
                  />
                </div>
              </div>
            </div>

            <div className="productinner_container">
              <div className="content_cont brown_container">
                <div className="heading">Specialised Application</div>
                <div className="productinner_heading_desc">
                  <ul>
                    <li>Bullet proof jackets</li>
                    <li>Fireproof Jackets</li>
                    <li>High Altitude Combat Gear</li>
                    <li>Space Applications</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-6 p-0 order-2">
                  <img
                    src={image18}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img"
                  />
                </div>
                <div className="col-6 col-lg-4 p-0 order-1">
                  <img
                    src={image17}
                    alt="sanathan product img"
                    loading="lazy"
                    className="img-fluid product_inner_img2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ouryarn;
