import { useEffect } from "react";
import "./Popsecond.css";
import "./pop2.scss";
import { pop2 } from "../../images";
import Dash from "../Dash/Dash";
import { Link } from "react-router-dom";

const Popsecond = ({ onRequestClose }) => {
  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        console.log("close clicked");
        onRequestClose();
      }
    }

    // Prevent scolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });
  useEffect(() => {
    window.scrollTo({
      top: 850,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <section className="popup_form_wrapper2">
        <div className="products_popup2">
          <div className="img_and_close_button">
            <figure>
              <img src={pop2} alt="sanathan Fully Drawn Yarn" />
            </figure>
            <button type="button" onClick={onRequestClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="wrapper">
            <h3>Fully Drawn Yarn</h3>
            <p>
              We manufacture Polyester Fully Drawn Yarns in a wide range of
              deniers starting from 40 deniers to 1000 deniers.
              <br />
              <br /> Having in-house continuous polymerization capacity to
              provide yarn in Semi-dull, Bright, Cationic, Full-dull and Dope
              Dyed Yarn lustres, we can offer customers a wide range of FDY yarn
              products.
              <br />
              <br /> Our well-planned setup has the{" "}
              <Link to="/our-setup/infrastructure" className="new_link">
                latest Oerlikon Barmag FDY machines
              </Link>{" "}
              capable of making micro yarns and fine deniers <br />
              <br />
              Our FDY colour yarns BornDyed™ have a range of over 300 colours
              and experienced setup with the team to give made to order shades
              to suit the customers' customised needs.
              <br />
              <br />
              We have the latest Oerlikon Barmag machines are equipped with
              auto-doffing, auto-packing and auto warehousing facilities to
              ensure that the customer receives the yarn with no Human touch.
            </p>
            <section className="pop_sec3">
              <div className="row">
                <div className="col-lg-6 pop_box2">
                  <div className="pop_head">FDY Bright Trilobal</div>
                  <div className="pop_desc">
                    Denier range: from 40 denier to 800 denier
                  </div>
                  <div className="dashwrapper">
                    <span></span>
                  </div>
                  <div className="pop_head">FDY Semi-Dull Round</div>
                  <div className="pop_desc">
                    Denier range: from 40 to 300 denier
                  </div>
                </div>
                <div className="col-lg-6 pop_box2">
                  <div className="pop_head">FDY Bright Cationic Dyeable</div>
                  <div className="pop_desc">
                    Denier range: from 40 denier to 300 denier
                  </div>
                  <div className="dashwrapper">
                    <span></span>
                  </div>
                  <div className="pop_head">FDY Semi-Dull Cationic Dyeable</div>
                  <div className="pop_desc">
                    Denier range: from 40 to 300 denier
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default Popsecond;
