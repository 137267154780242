import { useEffect } from "react";
import "./OurSetupQualityAndRnD.css";

import Dash from "../../components/Dash/Dash";

import {
  QualityBannerImage1,
  QualityBannerImage2,
  RnDImage1,
  RnDImage2,
  QualitySliderImage,
  QualitySliderImage2,
  QualitySliderImage3,
} from "../../images";

//swiper imports
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
]);

const OurSetupQualityAndRnD = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    var our_quality_slider_wrapper = new Swiper(
      ".swiper-container.our_quality_slider_wrapper",
      {
        // autoplay: {
        //     delay: 2000
        // },
        // loop: true,
        // loopedSlides: 4,
        // slidesPerView: 3,
        // spaceBetween: 213,
        breakpoints: {
          // 1536: {
          //     spaceBetween: 40
          // },
          // 1480: {
          //     spaceBetween: 25
          // },
          // 1366: {
          //     spaceBetween: 30
          // },
          1023: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          375: {
            slidesPerView: 1.9,
            spaceBetween: 0,
          },
        },
        navigation: {
          nextEl: ".navigations .right_arrow",
          prevEl: ".navigations .left_arrow",
        },
      }
    );
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/our-setup/quality-and-r+d"
        metaTitle="Our Setup - Quality & R+D | Sanathan Textiles"
        metaDesc="We have a dedicated and skilled team for process management, quality controls and constant R & D. Focusing on developing new yarns for applications across multiple industries."
      />

      <section className="our_setup_quality_and_rnd">
        <div className="wrapper">
          <div class="breadcrumb_styles">
            Our Setup {">"} Quality {"&"} R+D
          </div>
          <div className="left_section">
            <p className="hollow_title">Our Setup</p>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <h1 className="quality">Quality {"&"} R+D</h1>
            <p>
              At Sanathan Textiles, we have a dedicated and skilled team for
              process management, quality controls and constant research and
              development. Focusing on developing new yarns to cater to varied
              end-use and applications across multiple industries.
            </p>
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={QualityBannerImage2} alt="sanathan img" />
            </figure>
            <figure className="fg_img">
              <img src={QualityBannerImage1} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>
      <section className="our_quality">
        <div className="wrapper">
          <div
            className="our_quality_slider_wrapper swiper-container"
            data-aos="fade-up"
          >
            <div className="our_quality_slider_heading_section">
              <div className="left_section">
                <h3>Our Quality</h3>
                {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p> */}
              </div>
              <div className="navigations our_quality_slider_navigations_desktop">
                <button className="left_arrow">Previous</button>
                <button className="right_arrow">Next</button>
              </div>
            </div>
            <ul className="swiper-wrapper">
              <li className="swiper-slide">
                <figure>
                  <img src={QualitySliderImage} alt="sanathan img" />
                </figure>
                {/* <span>Quality 01</span> */}
                <p>
                  Process Monitoring for consistent and good quality products.
                </p>
              </li>
              <li className="swiper-slide">
                <figure>
                  <img src={QualitySliderImage2} alt="sanathan img" />
                </figure>
                {/* <span>Quality 01</span> */}
                <p>Continual improvement in Process and product performance.</p>
              </li>
              <li className="swiper-slide">
                <figure>
                  <img src={QualitySliderImage3} alt="sanathan img" />
                </figure>
                {/* <span>Quality 01</span> */}
                <p>
                  To develop the special products as per Market Sample Analysis
                  of products
                </p>
              </li>
            </ul>
            <div className="navigations our_quality_slider_navigations_mobile">
              <button className="left_arrow">Previous</button>
              <button className="right_arrow">Next</button>
            </div>
          </div>
        </div>
      </section>
      <section className="rnd">
        <div className="wrapper" data-aos="fade-up">
          <div className="left_section">
            <div className="foreground">
              <div className="hollow_title">R {"&"} D</div>
              <figure className="fg_image">
                <img src={RnDImage1} alt="sanathan img" />
              </figure>
            </div>
            <figure className="bg_image">
              <img src={RnDImage2} alt="sanathan img" />
            </figure>
          </div>
          <div className="right_section">
            <h3>Research {"&"} Development</h3>
            <p className="right_section_p right_section_p_desktop">
              Developing new yarns to cater to varied end-users and applications
              requires constant market research and product development. Our
              experienced team is focused on this endeavour day and night in
              ensuring we keep ahead of the curve in making high value-added
              yarn products.
            </p>
            <p className="right_section_p right_section_p_mobile">
              Developing new yarns to cater to varied end-users and applications
              requires constant market research and product development. Our
              experienced team is focused on this endeavour day and night in
              ensuring we keep ahead of the curve in making high value-added
              yarn products.
            </p>
            <br />
            <br />
            <p>
              <strong>We achieve the above by focusing on the below:</strong>
            </p>
            <ul>
              <li>
                <p>Updating our lab facility with the latest equipment</p>
              </li>
              <li>
                <p>Constant product benchmarking</p>
              </li>
              <li>
                <p>Finding the problem and then working on a solution</p>
              </li>
              <li>
                <p>Understanding the customer’s purpose and desire</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurSetupQualityAndRnD;
