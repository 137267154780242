import React from "react";
import { Helmet } from "react-helmet";

const MetaDecorator = ({ metaTitle, metaDesc, canonicalLink, noIndex }) => {
  return (
    <Helmet>
      <link rel="canonical" href={canonicalLink}></link>
      {noIndex ? (
        <meta name="robots" content=" noindex, follow" />
      ) : (
        <meta name="robots" content="index, follow" />
      )}
      <title>{metaTitle}</title>
      <meta name="description" content={metaDesc} />
    </Helmet>
  );
};

export default MetaDecorator;
