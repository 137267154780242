import { useState } from "react";
import "./Footer.css";

import SalesEnquiry from "../SalesEnquiry/SalesEnquiry";
import Careers from "../Careers/Careers";

import { fb, linkedin, twitter, youtube, instagram } from "../../images";

const Footer = () => {
  const [isModalSalesOpen, setSalesModalIsOpen] = useState(false);
  const [isModalCareersOpen, setCareersModalIsOpen] = useState(false);
  const currentYear = new Date();

  const toggleSalesModal = () => {
    setSalesModalIsOpen(!isModalSalesOpen);
  };
  const toggleCareersModal = () => {
    setCareersModalIsOpen(!isModalCareersOpen);
  };

  return (
    <>
      {isModalSalesOpen && <SalesEnquiry onRequestClose={toggleSalesModal} />}
      {isModalCareersOpen && <Careers onRequestClose={toggleCareersModal} />}
      <footer>
        <div className="top_footer">
          <div className="wrapper">
            <h6>Follow us</h6>
            <ul>
              <li>
                <ul className="social_icons">
                  <li>
                    <a
                      href="https://youtube.com/channel/UCt9TLtYA5ayira8PRzXx9ig"
                      target="_blank"
                    >
                      <img
                        className="youtube_img"
                        src={youtube}
                        alt="sanathan youtube logo"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/sanathantextiles/"
                      target="_blank"
                    >
                      <img
                        className="insta_img"
                        src={instagram}
                        alt="sanathan instagram logo"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/Sanathan-Textiles-204062499635407"
                      target="_blank"
                    >
                      <img className="fb_img" src={fb} alt="sanathan facebook logo" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://in.linkedin.com/company/sanathan-textiles-private-limited"
                      target="_blank"
                    >
                      <img
                        className="linkedin_img"
                        src={linkedin}
                        alt="sanathan linkedin logo"
                      />
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                {/* <h6>Sales Enquiry</h6>
                <a href="mailto:contactus@sanathan.com">
                  contactus@sanathan.com
                </a> */}
                <button onClick={toggleSalesModal} type="button">
                  Contact Us
                </button>
              </li>
              <li>
                {/* <h6>Careers</h6>
                <a href="mailto:joinus@sanathan.com">joinus@sanathan.com</a> */}
                <button onClick={toggleCareersModal} type="button">
                  Careers
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="bottom_footer">
          <div className="wrapper">
            <ul className="left left_desktop">
              {/* <li>
                <button onClick={toggleSalesModal} type="button">
                  Sales Enquiry
                </button>
              </li>
              <li>
                <button onClick={toggleCareersModal} type="button">
                  Careers
                </button>
              </li> */}
              <li>Copyright ©{currentYear.getFullYear()} Sanathan Textiles</li>
            </ul>
            <ul className="right right_desktop">
              {/* <li>Copyright ©2021 Sanathan Textiles</li> */}
              <li>
                <a href="https://www.togglehead.in/" target="_blank">
                  Crafted by Togglehead
                </a>
              </li>
              {/* <li><p>Privacy Policy</p></li>
                            <li><p>Terms {"&"} Conditions</p></li> */}
            </ul>
            <ul className="left left_mobile">
              {/* <li>
                <button onClick={toggleSalesModal} type="button">
                  Sales Enquiry
                </button>
              </li>
              <li>
                <button onClick={toggleCareersModal} type="button">
                  Careers
                </button>
              </li> */}
            </ul>
            <ul className="right right_mobile">
              {/* <li>Privacy Policy</li> */}
              {/* <li>Terms {"&"} Conditions</li> */}
              <li>
                <a href="https://www.togglehead.in/" target="_blank">
                  Crafted by Togglehead
                </a>
              </li>
              <li>Copyright ©{currentYear.getFullYear()} Sanathan Textiles</li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
