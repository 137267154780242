import { useEffect, useState } from "react";
import "./OurSales.css";
import "./style.scss";

import Dash from "../../components/Dash/Dash";

import ReactTooltip from "react-tooltip";
import Maps from "../../components/Maps/Maps";

import {
  IconImg1,
  IconImg2,
  IconImg3,
  LogoImg1,
  LogoImg2,
  LogoImg3,
  LogoImg4,
  LogoImg5,
  BrandImage1,
  BrandImage2,
  BrandImage3,
  BrandImage4,
  BrandImage5,
  BrandImage6,
  BrandImage7,
  BrandImage8,
  BrandImage9,
  BrandImage10,
  BrandImage11,
  BrandImage12,
  BrandImage13,
  BrandImage14,
  BrandImage15,
  BrandImage16,
  BrandImage17,
  BrandImage18,
  BrandImage19,
  BrandImage20,
  BrandImage21,
  BrandImage22,
  BrandImage23,
  BrandImage24,
  BrandImage25,
  BrandImage26,
  BrandImage27,
  BrandImage28,
  BrandImage29,
  BrandImage30,
} from "../../images";

//swiper imports
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import { Link } from "react-router-dom";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
]);

const OurSales = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    var clients_slider_wrapper = new Swiper(
      ".swiper-container.clients_slider_wrapper",
      {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        freeMode: true,
        grabCursor: false,
        allowTouchMove: false,
        slidesPerView: 7,
        autoplay: {
          delay: 1,
          disableOnInteraction: true,
        },
        speed: 2500,
        navigation: {
          nextEl: ".clients_slider_wrapper .right_arrow",
          prevEl: ".clients_slider_wrapper .left_arrow",
        },
        spaceBetween: 40,
        breakpoints: {
          300: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
          },
          1023: {
            slidesPerView: 5.5,
          },
        },
      }
    );
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/our-sales"
        metaTitle="Our Sales |Sanathan Textiles"
        metaDesc="We have distributors and partners in multiple locations around the world to enable us with consistent service, flexibility and supply of our innovative Yarn products."
      />

      <section className="our_sales_section1" data-aos="fade-up">
        <div className="wrapper">
          <div class="breadcrumb_styles">
            <Link to="/" className="breadcrumb_link">
              Home
            </Link>{" "}
            {">"} Our Sales
          </div>
          <h1 className="hollow_title">Our Sales</h1>
          <div className="dash_wrapper">
            <Dash />
          </div>
          <div className="our_sales_heading">
            <h3>Our Yarns travel across the globe</h3>
            <p>
              As on December 31, 2023, we had 902 distributors in 7 countries
              including India which was instrumental in an effective
              supply-chain management and helped augment our sales across
              geographies. We are supported by reputed third party distributors
              across geographies with whom we have been associated for more than
              10 years. We believe that our long-standing relationships with
              such reputed third-party distributorship network has been possible
              due to the continued production of quality products. Such
              relationships with third-party distributorship network have
              assisted us in scaling of our business, increasing our
              international presence and has helped us enhance our goodwill.
            </p>
          </div>
          {/* <div className="map_wrapper">
            <Maps setTooltipContent={setContent} />
            <ReactTooltip>{content}</ReactTooltip>
          </div> */}
        </div>
      </section>

      {/* <section className="map_sec2" data-aos="fade-up">
        <div className="wrapper">
          <div className="row">
            <div className="col-6">
              <div className="product_items">
                <ul>
                  <li>
                    <p>USA</p>
                  </li>
                  <li>
                    <p>Spain</p>
                  </li>
                  <li>
                    <p>Chile</p>
                  </li>
                  <li>
                    <p>Uruguay</p>
                  </li>
                  <li>
                    <p>Djibouti</p>
                  </li>
                  <li>
                    <p>Morocco</p>
                  </li>
                  <li>
                    <p>Algeria</p>
                  </li>
                  <li>
                    <p>Germany</p>
                  </li>
                  <li>
                    <p>France</p>
                  </li>
                  <li>
                    <p>Turkey</p>
                  </li>
                  <li>
                    <p>Thailand</p>
                  </li>
                  <li>
                    <p>South Korea</p>
                  </li>
                  <li>
                    <p>Ukraine</p>
                  </li>
                  <li>
                    <p>Honduras</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6">
              <div className="product_items">
                <ul>
                  <li>
                    <p>UK</p>
                  </li>
                  <li>
                    <p>Argentina</p>
                  </li>
                  <li>
                    <p>Columbia</p>
                  </li>
                  <li>
                    <p>South Africa</p>
                  </li>
                  <li>
                    <p>Egypt</p>
                  </li>
                  <li>
                    <p>Tunisia</p>
                  </li>
                  <li>
                    <p>Portugal</p>
                  </li>
                  <li>
                    <p>Italy</p>
                  </li>
                  <li>
                    <p>Belgium</p>
                  </li>
                  <li>
                    <p>Nepal</p>
                  </li>
                  <li>
                    <p>Japan</p>
                  </li>
                  <li>
                    <p>Russia</p>
                  </li>
                  <li>
                    <p>Sri Lanka</p>
                  </li>
                  <li>
                    <p>Guatemala</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="clients">
        <div className="clients_slider_wrapper swiper-container">
          <div className="clients_slider_wrapper_heading_section">
            <div className="left_section">
              <div className="hollow_title">Our Customers</div>
              <h3>We supply to 1150+ customers worldwide</h3>
            </div>
            {/* <div className="navigations clients_navigations">
              <button className="left_arrow">Previous</button>
              <button className="right_arrow">Next</button>
            </div> */}
          </div>
          <ul className="swiper-wrapper">
            <li className="swiper-slide">
              <img src={BrandImage1} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage2} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage3} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage4} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage5} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage6} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage7} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage8} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage9} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage10} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage11} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage12} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage13} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage14} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage15} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage16} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage17} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage18} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage19} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage20} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage21} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage22} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage23} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage24} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage25} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage26} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage27} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage28} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage29} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage30} alt="sanathan img" />
            </li>
          </ul>
          {/* <div class="swiper-pagination"></div> */}
        </div>
      </section>
      {/* <section className="our_sales_section2" data-aos="fade-up">
        <div className="oursales_slider_wrapper swiper-container">
          <div className="oursales_slider_wrapper_heading_section">
            <div className="left_section">
              <div className="hollow_title">Clients</div>
              <h3>oursales we served</h3>
            </div>
            <div className="navigations">
              <button className="left_arrow">Previous</button>
              <button className="right_arrow">Next</button>
            </div>
          </div>
          <ul className="swiper-wrapper">
            <li className="swiper-slide">
              <img src={BrandImage1}  alt="sanathan img"/>
            </li>
            <li className="swiper-slide">
              <img src={BrandImage2}  alt="sanathan img"/>
            </li>
            <li className="swiper-slide">
              <img src={BrandImage3}  alt="sanathan img"/>
            </li>
            <li className="swiper-slide">
              <img src={BrandImage4}  alt="sanathan img"/>
            </li>
            <li className="swiper-slide">
              <img src={BrandImage5}  alt="sanathan img"/>
            </li>
            <li className="swiper-slide">
              <img src={BrandImage6}  alt="sanathan img"/>
            </li>
            <li className="swiper-slide">
              <img src={BrandImage7}  alt="sanathan img"/>
            </li>
            <li className="swiper-slide">
              <img src={BrandImage8}  alt="sanathan img"/>
            </li>
            <li className="swiper-slide">
              <img src={BrandImage9}  alt="sanathan img"/>
            </li>
            <li className="swiper-slide">
              <img src={BrandImage10} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage11} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage12} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage13} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage14} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage15} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage16} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage17} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage18} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage19} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage20} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage21} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage22} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage23} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage24} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage25} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage26} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage27} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage28} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage29} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage30} alt="sanathan img" />
            </li>
          </ul>
          <div class="swiper-pagination"></div>
        </div>
      </section> */}
    </>
  );
};

export default OurSales;
