import { useEffect } from "react";
import "./OurSetupTechnology.css";

import Dash from "../../components/Dash/Dash";

import {
  TechnologyBannerImage1,
  TechnologyBannerImage2,
  DigitalizationImage1,
  DigitalizationImage2,
  DigitalizationImage3,
  DigitalizationImage4,
  SustainabilityImage1,
  SustainabilityImage2,
  SustainabilityImage3,
  SustainabilityImage4,
  SustainabilityImage5,
  SustainabilityImage6,
  SustainabilityImage7,
  SustainabilityImage8,
  digiimg1,
  digiimg2,
  digiimg3,
  digiimg4,
} from "../../images";

//swiper imports
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
]);

const OurSetupTechnology = () => {
  const { width } = useWindowDimensions();
  useEffect(() => {
    var sustainability_swiper_wrapper = new Swiper(
      ".swiper-container.sustainability_swiper_wrapper",
      {
        slidesPerView: 2.2,
        spaceBetween: 39,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".navigations .right_arrow",
          prevEl: ".navigations .left_arrow",
        },
        320: {
          slidesPerView: 1.1,
        },
      }
    );
    var sus_container = new Swiper(".swiper-container.sus_container", {
      slidesPerView: 1.2,
      spaceBetween: 20,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/our-setup/tech-in-textile"
        metaTitle="Our Setup - Tech in Textile | Sanathan Textiles"
        metaDesc="At Sanathan, Our setup is abled with the appropriate amount of automation and technology to enable our team for better and more consistent performance."
      />

      <section className="technosec1" data-aos="fade-in">
        <div className="row">
          <div className="col-lg-5 col_1">
            <h5 className="breadcrumb_styles">
              Our Setup {">"} Tech In Textile
            </h5>
            <h2 className="hollow_title">Our Setup</h2>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <h3>Tech in Textile</h3>
            <p>
              In today’s time technology is a wonderful tool and we at Sanathan
              Textiles are constantly trying to use this to our advantage. Our
              setup is abled with the appropriate amount of automation and
              technology to enable our team for better and more consistent
              performance.
              <br />
              <br />
              <ul className="ul">
                <li>
                  Automatic doffing, transportation and package handling systems
                  to meet global inventory management standards.
                </li>
                <li>
                  Automated warehouse management system to ensure high
                  efficiency, less damage and better inventory management in
                  limited space for handling over 1,00,000 SKU.
                </li>
                <li>
                  Process automation helps with the reduction of labour
                  dependency while technology helps to reduce production costs.
                </li>
                <li>
                  Data-driven decision-making for higher profitability and
                  timely management of production to service a variety of
                  products.
                </li>
              </ul>
            </p>
          </div>
          <div className="col-lg-7 col_2">
            <img
              src={TechnologyBannerImage2}
              alt="sanathan tech banner"
              className="tech_banner_bg"
            />
            <iframe
              src="https://www.youtube.com/embed/a6y1xzN1WD8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              className="tech_banner_video"
            ></iframe>
          </div>
        </div>
      </section>

      <section className="technosec2" data-aos="fade-up">
        <div className="row row1">
          <div className="col-md-6 col_1">
            <h1>digitization & technology</h1>
          </div>
          <div className="col-md-6 col_2">
            {/* <p className="digi_para">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet
            </p> */}
          </div>
        </div>
        <div className="digi_wrapper">
          <div className="row row2">
            <div className="col-lg-3 col-md-6">
              <img
                src={digiimg1}
                alt="sanathan digitization"
                className="digi_img"
              />
              <p>Technology driven production control</p>
            </div>
            <div className="col-lg-3 col-md-6">
              <img
                src={digiimg2}
                alt="sanathan digitization"
                className="digi_img"
              />
              <p>Traceability and transparency of process</p>
            </div>
            <div className="col-lg-3 col-md-6">
              <img
                src={digiimg3}
                alt="sanathan digitization"
                className="digi_img"
              />
              <p>Digitized infrastructure</p>
            </div>
            <div className="col-lg-3 col-md-6">
              <img
                src={digiimg4}
                alt="sanathan digitization"
                className="digi_img"
              />
              <p>Data driven decision making</p>
            </div>
          </div>
        </div>
      </section>
      {width > 767 ? (
        <section
          className="our_setup_technology_second_section"
          data-aos="fade-up"
        >
          <div className="sustainability">
            <h3>Sustainability with Technology</h3>
            <div className="sustainability_swiper_wrapper swiper-container">
              <ul className="swiper-wrapper">
                <li className="swiper-slide">
                  <div>
                    <p>
                      Energy efficient plant design with the use of technology
                      to reduce power consumption
                    </p>
                  </div>
                  <figure>
                    <img src={SustainabilityImage1} alt="sanathan img" />
                    {/* <img src={SustainabilityImage5} className="sustainability_small_icons" alt="sanathan img"/> */}
                  </figure>
                </li>
                <li className="swiper-slide">
                  <div>
                    <p>
                      Sustainable production processes with the use of
                      technology, data driven process and latest solutions
                    </p>
                  </div>
                  <figure>
                    <img src={SustainabilityImage3} alt="sanathan img" />
                    {/* <img src={SustainabilityImage6} className="sustainability_small_icons" alt="sanathan img"/> */}
                  </figure>
                </li>
                <li className="swiper-slide">
                  <div>
                    <p>
                      Organic horizantal and vertical growth plans to add to our
                      sustainable business model
                    </p>
                  </div>
                  <figure>
                    <img src={SustainabilityImage2} alt="sanathan img" />
                    {/* <img src={SustainabilityImage7} className="sustainability_small_icons"alt="sanathan img"/> */}
                  </figure>
                </li>
                <li className="swiper-slide">
                  <div>
                    <p>Enviromental friendly decision making</p>
                  </div>
                  <figure>
                    <img src={SustainabilityImage4} alt="sanathan img" />
                    {/* <img src={SustainabilityImage8} className="sustainability_small_icons" alt="sanathan img"/> */}
                  </figure>
                </li>
              </ul>
            </div>
            <div className="navigations">
              <button className="left_arrow">Previous</button>
              <button className="right_arrow">Next</button>
            </div>
          </div>
        </section>
      ) : (
        <section className="technosec3">
          <span>Sustainability with Technology</span>
          <div className="swiper-container sus_container">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="sus_img_text_wrapper">
                  <div className="sus-text">
                    <span className="sus_para">
                      Energy efficient plant design with the use of technology
                      to reduce power consumption
                    </span>
                  </div>
                  <img
                    src={SustainabilityImage1}
                    alt="sanathan img"
                    className="sus_img"
                  />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="sus_img_text_wrapper">
                  <div className="sus-text">
                    <span className="sus_para">
                      Sustainable production processes with the use of
                      technology, data driven process and latest solutions
                    </span>
                  </div>
                  <img
                    src={SustainabilityImage3}
                    alt="sanathan img"
                    className="sus_img"
                  />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="sus_img_text_wrapper">
                  <div className="sus-text">
                    <span className="sus_para">
                      Organic horizantal and vertical growth plans to add to our
                      sustainable business model
                    </span>
                  </div>
                  <img
                    src={SustainabilityImage2}
                    alt="sanathan img"
                    className="sus_img"
                  />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="sus_img_text_wrapper">
                  <div className="sus-text">
                    <span className="sus_para">
                      Enviromental friendly decision making
                    </span>
                  </div>
                  <img
                    src={SustainabilityImage4}
                    alt="sanathan img"
                    className="sus_img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default OurSetupTechnology;
