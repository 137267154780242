import React, { useEffect, useState } from "react";
import "./Banner.scss";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";

export const Banner = ({
  imgSrc,
  breadcrumbWord1,
  breadcrumbWord2,
  breadcrumbWord3,
  breadcrumbWord4,
  link1,
  link2,
  link3,
  disabled,
}) => {
  return (
    <>
      <main>
        <section className="common_banner">
          <img
            src={imgSrc}
            alt="caterina banner img"
            className="img-fluid common_banner_img"
            id="bannerImg"
            onLoad={imgSrc}
          />
          <div className="breadcrumb_wrapper">
            <div className="common_breadcrumb_line1">
              <Link to={link1 ? link1 : ""} className="common_breadcrumb_link">
                {breadcrumbWord1}
              </Link>
              <Link
                to={link2 ? link2 : ""}
                className={`common_breadcrumb_link ${
                  disabled ? "disabled_link" : ""
                }`}
              >
                {breadcrumbWord2 ? `| ${breadcrumbWord2} ` : ""}
              </Link>
              <Link to={link3 ? link3 : ""} className="common_breadcrumb_link">
                {breadcrumbWord3 ? `| ${breadcrumbWord3}` : ""}
              </Link>
            </div>
            <div className="common_breadcrumb_line2">{breadcrumbWord4}</div>
          </div>
        </section>
      </main>
    </>
  );
};

// export default Banner;
