import React, { useEffect, useState } from "react";
import "./ContactUs.scss";
import Joi from "joi";

import Dash from "../../components/Dash/Dash";

import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  bg,
  coloredArrow,
  cfb,
  clinkedin,
  ctwitter,
  cyoutube,
  cinstagram,
  call,
  emailImg,
  location,
} from "../../images";

import TextField from "@mui/material/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";

import Thankyou from "../../components/Thankyou/Thankyou";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const ContactUs = () => {
  const [isModalThankyouOpen, setThankyouModalIsOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [selectOption, setSelectOption] = useState("");
  const [contactDetails, setContactDetails] = useState({
    full_name: "",
    email: "",
    phone: "",
    message: "",
    role: "",
  });

  const { full_name, email, phone, message, role } = contactDetails;

  const [contactDetailsErr, setContactDetailsErr] = useState({
    fullNameErr: "",
    emailErr: "",
    phoneErr: "",
    messageErr: "",
    roleErr: "",
  });

  const handleFormFieldsErr = (errField, message) => {
    setContactDetailsErr((prevState) => {
      return {
        ...prevState,
        [errField]: message,
      };
    });
  };

  const toggleThankyouModal = () => {
    setThankyouModalIsOpen(!isModalThankyouOpen);
  };

  const emailRegexPattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  const alphaCharRegex = /^[a-zA-Z ]*$/;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let formIsValid = true;

    if (!full_name.trim()) {
      formIsValid = false;
      handleFormFieldsErr("fullNameErr", "*This field is required");
    } else if (!full_name.match(alphaCharRegex)) {
      formIsValid = false;
      handleFormFieldsErr(
        "fullNameErr",
        "*Please enter alphabet characters only."
      );
    } else {
      handleFormFieldsErr("fullNameErr", "");
    }

    if (!email.trim()) {
      formIsValid = false;
      handleFormFieldsErr("emailErr", "This field is required");
    } else if (!emailRegexPattern.test(email)) {
      formIsValid = false;
      handleFormFieldsErr("emailErr", "*Please enter valid email-Id.");
    } else {
      handleFormFieldsErr("emailErr", "");
    }

    if (!phoneNumber) {
      formIsValid = false;
      handleFormFieldsErr("phoneErr", "*This field is requried");
    } else if (!isPossiblePhoneNumber(phoneNumber.toString())) {
      formIsValid = false;
      handleFormFieldsErr("phoneErr", "*Please enter valid mobile number.");
    } else {
      handleFormFieldsErr("phoneErr", "");
    }
    if (!message) {
      formIsValid = false;
      handleFormFieldsErr("messageErr", "*This field is requried");
    } else {
      handleFormFieldsErr("messageErr", "");
    }
    if (!role) {
      formIsValid = false;
      handleFormFieldsErr("roleErr", "*This field is requried");
    } else {
      handleFormFieldsErr("roleErr", "");
    }
    if (formIsValid) {
      formSubmit();
    }
  };

  const formSubmit = async () => {
    const { data } = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/contact`,
      data: contactDetails,
    });
    if (data.status === 200) {
      toggleThankyouModal();
      console.log("success");
      setContactDetails({
        full_name: "",
        email: "",
        phone: "",
        message: "",
        role: "",
      });
      setSelectOption("");
      setPhoneNumber("");
    } else {
      toast.error(data.message, {
        autoClose: 5000,
      });
    }
  };

  const handleInput = (event) => {
    const { name, value } = event.target;

    setContactDetails((preValue) => {
      return {
        ...preValue,
        [name]: value,
        phone: phoneNumber,
      };
    });
  };
  // console.log(phoneNumber.length);

  const handleChange = (event) => {
    setContactDetails((preValue) => {
      return {
        ...preValue,
        role: event.target.value,
      };
    });
    setSelectOption(event.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/contact-us"
        metaTitle="Contact Us | Sanathan Textiles"
        metaDesc="Fill in the contact form or mail us at sales@sanathan.com for business queries. We will get back at the earliest."
      />
      {isModalThankyouOpen && <Thankyou onRequestClose={toggleThankyouModal} />}
      <section className="contactUs" data-aos="fade-in">
        <div className="wrapper">
          <div class="breadcrumb_styles">
            <Link to="/" className="breadcrumb_link">
              Home
            </Link>{" "}
            {">"} Contact Us
          </div>
          <div className="contact_us_wrapper">
            <div className="left_section">
              <h1 className="hollow_title">Contact Us</h1>
              <div className="dash_wrapper">
                <Dash />
              </div>
              <h3>Say hello to us!</h3>
              <div className="form_wrapper">
                <form onSubmit={handleFormSubmit} id="contactus_form">
                  <div className="form_input_wrapper form-group">
                    <TextField
                      type="text"
                      name="full_name"
                      label="Full Name*"
                      variant="standard"
                      value={contactDetails.full_name}
                      onChange={handleInput}
                      // required
                    />
                    {/* <input
                                                type="text"
                                                name="full_name"
                                                className="form_input form-control"
                                                placeholder="Full Name*"
                                                value={contactDetails.full_name}
                                                onChange={handleInput}
                                            /> */}
                    <div className="err">{contactDetailsErr.fullNameErr}</div>
                  </div>
                  <div className="form_input_wrapper form-group">
                    <TextField
                      type="email"
                      name="email"
                      label="Email Id*"
                      variant="standard"
                      value={contactDetails.email}
                      onChange={handleInput}
                      // required
                    />
                    {/* <input
                                                type="email"
                                                name="email"
                                                className="form_input form-control"
                                                placeholder="Email Id*"
                                                value={contactDetails.email}
                                                onChange={handleInput}
                                            /> */}
                    <div className="err">{contactDetailsErr.emailErr}</div>
                  </div>
                  <div className="form_input_wrapper contact_wrapper form-group">
                    {/* <MuiPhoneNumber
                                            defaultCountry={'in'}
                                            name="phone"
                                            placeholder="Phone"
                                            value={phoneNumber}
                                            onChange={setPhoneNumber}
                                            countryCodeEditable={false}
                                            // required
                                        /> */}
                    <PhoneInput
                      style={{ backgroundColor: "white" }}
                      defaultCountry="IN"
                      placeholder="Phone"
                      international
                      countryCallingCodeEditable={false}
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                    />
                    {/* <PhoneInput
                                                defaultCountry="IN"
                                                name="phone"
                                                placeholder="Phone*"
                                                international
                                                countryCallingCodeEditable={false}
                                                value={phoneNumber}
                                                onChange={setPhoneNumber}
                                                className="form_input form-control"
                                            /> */}
                    <div className="err">{contactDetailsErr.phoneErr}</div>
                  </div>

                  <div className="form_input_wrapper form-group contact_select_wrapper">
                    <FormControl variant="standard" sx={{ margin: 0 }}>
                      <InputLabel id="dare_you" sx={{ margin: 0 }}>
                        Let us know more about you
                      </InputLabel>
                      <Select
                        className="are_you_select"
                        labelId="are_you"
                        value={selectOption}
                        onChange={handleChange}
                        label="Let us know more about you"
                        sx={{
                          ".MuiInput-input:focus": {
                            backgroundColor: "#ffffff",
                          },
                        }}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        // InputLabelProps={{
                        // style: {
                        //   fontSize: "1.2em",
                        // },
                        // }}
                      >
                        <MenuItem value="Manufacturer">Manufacturer</MenuItem>
                        <MenuItem value="Retailer">Retailer</MenuItem>
                        <MenuItem value="Seller">Seller</MenuItem>
                        <MenuItem value="⁠Investor">⁠Investor</MenuItem>
                        <MenuItem value="Media and PR">Media and PR</MenuItem>
                      </Select>
                    </FormControl>
                    <div className="err">{contactDetailsErr.roleErr}</div>
                  </div>

                  <div className="form_input_wrapper form-group">
                    <TextField
                      type="text"
                      name="message"
                      label="Message*"
                      variant="standard"
                      value={contactDetails.message}
                      onChange={handleInput}
                      // required
                    />
                    {/* <textarea
                                                name="message"
                                                className="textarea form_input form-control"
                                                placeholder="Message*"
                                                value={contactDetails.message}
                                                onChange={handleInput}
                                            ></textarea> */}
                    <div className="err">{contactDetailsErr.messageErr}</div>
                  </div>
                  <div className="submit_button_container">
                    <button
                      type="submit"
                      className="sanathan_submit_btn"
                      id="contactus_form_submit_btn"
                    >
                      submit
                    </button>
                    <span className="arrow">
                      <img src={coloredArrow} alt="sanathan img" />
                    </span>
                  </div>
                </form>
              </div>
            </div>
            <div className="right_section">
              <figure>
                <img src={bg} alt="sanathan img" />
              </figure>
              <div className="right_section_content">
                <h3>Contact Information</h3>
                <ul className="contact_details">
                  <li>
                    <img src={location} alt="sanathan img" />
                    <p>
                      D15, trade world, kamala mill compound, senapati bapat
                      Marg, lower Parel West Mumbai, Maharashtra 400013.
                    </p>
                  </li>
                  <li>
                    <img src={emailImg} alt="sanathan img" />
                    <a href="mailto:sales@sanathan.com" className="email">
                      sales@sanathan.com
                    </a>
                  </li>
                  {/* <li>
                                        <img src={call} alt="sanathan img"/>
                                        <a>+91 123456789</a>
                                    </li> */}
                </ul>
                <ul className="social_icons">
                  <li>
                    <a
                      href="https://youtube.com/channel/UCt9TLtYA5ayira8PRzXx9ig"
                      target="_blank"
                    >
                      <img src={cyoutube} alt="youtube" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/sanathantextiles/"
                      target="_blank"
                    >
                      <img src={cinstagram} alt="instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/Sanathan-Textiles-204062499635407"
                      target="_blank"
                    >
                      <img src={cfb} alt="facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://in.linkedin.com/company/sanathan-textiles-private-limited"
                      target="_blank"
                    >
                      <img src={clinkedin} alt="linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="map_wrapper" data-aos="fade-up">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.370020343875!2d72.82589831472852!3d19.00340998712961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ceecd7aceb53%3A0xea24d5c21a81622d!2sSanathan%20Textiles%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1638796963959!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </section>
    </>
  );
};

export default ContactUs;
