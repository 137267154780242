import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Header.css";
import "./Header.scss";

import { ColorLogo, WhiteLogo } from "../../images";

const Header = () => {
  const [colorChange, setColorchange] = useState(false);
  const [notHomePage, setNotHomePage] = useState(false);
  const [notYarnPage, setNotYarnPage] = useState(false);
  const [aboutDropDown, setAboutDropDown] = useState(false);
  const [setupDropDown, setSetupDropDown] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  var width = window.innerWidth;

  // const [path, setPath] = useState("/");

  useEffect(() => {
    console.log(window.location.pathname);
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/ouryarn-in-your-life"
    ) {
      window.addEventListener("scroll", changeNavbarColor);
    } else {
      setNotHomePage(true);
    }
    // if (window.location.pathname === "/ouryarn-in-your-life") {
    //   window.addEventListener("scroll", changeNavbarColorYarn);
    // } else {
    //   setNotYarnPage(true);
    // }
    return () => {
      window.removeEventListener("scroll", () => changeNavbarColor);
    };
  }, [window.location.pathname]);

  const changeNavbarColor = () => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/ouryarn-in-your-life"
    ) {
      if (window.scrollY >= 80) {
        setColorchange(true);
      } else {
        setColorchange(false);
      }
    }
  };
  // const changeNavbarColorYarn = () => {
  //   if (window.location.pathname === "/ouryarn-in-your-life") {
  //     if (window.scrollY >= 80) {
  //       setColorchange(true);
  //     } else {
  //       setColorchange(false);
  //     }
  //   }
  // };

  const handleClick = () => {
    setOpenMenu(!openMenu);
  };

  const handleAboutMenu = () => {
    setAboutDropDown((prev) => !prev);
    setSetupDropDown(false);
  };
  const handleSetupMenu = () => {
    setSetupDropDown((prev) => !prev);
    setAboutDropDown(false);
  };

  useEffect(() => {
    setOpenMenu(false);
    setAboutDropDown(false);
    setSetupDropDown(false);
  }, [window.location.pathname]);

  return (
    <>
      {width > 1023 ? (
        notHomePage ? (
          <header className="header">
            <div className="wrapper">
              <div className="header_link">
                <Link to="/">
                  <img
                    className="img_width"
                    src={ColorLogo}
                    alt="Sanathan Textiles Logo"
                  />
                </Link>
              </div>
              <nav>
                <ul className={colorChange ? "top_nav" : "top_nav"}>
                  {/* <li>
                      <NavLink exact to="#" activeClassName="active">
                        virtual tour
                      </NavLink>
                    </li> */}
                  {/* <li>
                      <NavLink exact to="/contact-us" activeClassName="active">
                        contact us
                      </NavLink>
                    </li> */}
                </ul>
                <ul className={colorChange ? "bottom_nav" : "bottom_nav"}>
                  <li>
                    <div className="dropdown_custom">
                      <button>
                        about us
                        <i className="fa fa-caret-down"></i>
                      </button>
                      <div className="dropdown_content">
                        <NavLink
                          exact
                          to="/about-us/the-company"
                          activeClassName="active"
                        >
                          our company
                        </NavLink>
                        <NavLink
                          exact
                          to="/about-us/our-team"
                          activeClassName="active"
                        >
                          Our Team
                        </NavLink>
                        <NavLink
                          exact
                          to="/about-us/our-journey"
                          activeClassName="active"
                        >
                          our journey
                        </NavLink>
                      </div>
                    </div>
                  </li>
                  <li>
                    <NavLink exact to="/products" activeClassName="active">
                      products
                    </NavLink>
                  </li>
                  <li>
                    <div className="dropdown_custom">
                      <button>
                        our setup
                        <i className="fa fa-caret-down"></i>
                      </button>
                      <div className="dropdown_content">
                        <NavLink
                          exact
                          to="/our-setup/infrastructure"
                          activeClassName="active"
                        >
                          infrastructure
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/tech-in-textile"
                          activeClassName="active"
                        >
                          Tech In Textile
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/certifications"
                          activeClassName="active"
                        >
                          certifications
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/quality-and-r+d"
                          activeClassName="active"
                        >
                          quality and r+d
                        </NavLink>
                      </div>
                    </div>
                  </li>
                  <li>
                    <NavLink exact to="/our-sales" activeClassName="active">
                      our sales
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/investor-relations"
                      activeClassName="active"
                    >
                      investors
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/blogs" activeClassName="active">
                      Blogs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/esg" activeClassName="active">
                      esg
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/newsroom" activeClassName="active">
                      newsroom
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/contact-us" activeClassName="active">
                      contact us
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </header>
        ) : (
          <header className={colorChange ? "changeColor" : "homepage"}>
            <div className="wrapper">
              <div className="header_link">
                <Link to="/">
                  <img
                    src={colorChange ? ColorLogo : WhiteLogo}
                    alt="Sanathan Textiles Logo"
                  />
                </Link>
              </div>
              <nav>
                <ul className={colorChange ? "top_nav" : "top_nav"}>
                  {/* <li>
                      <NavLink exact to="#" activeClassName="active">
                        virtual tour
                      </NavLink>
                    </li> */}
                  {/* <li>
                      <NavLink exact to="/contact-us" activeClassName="active">
                        contact us
                      </NavLink>
                    </li> */}
                </ul>
                <ul className={colorChange ? "bottom_nav" : "bottom_nav"}>
                  <li>
                    <div className="dropdown_custom">
                      <button>
                        about us
                        <i className="fa fa-caret-down"></i>
                      </button>
                      <div className="dropdown_content">
                        <NavLink
                          exact
                          to="/about-us/the-company"
                          activeClassName="active"
                        >
                          Our Company
                        </NavLink>
                        <NavLink
                          exact
                          to="/about-us/our-team"
                          activeClassName="active"
                        >
                          Our Team
                        </NavLink>
                        <NavLink
                          exact
                          to="/about-us/our-journey"
                          activeClassName="active"
                        >
                          our journey
                        </NavLink>
                      </div>
                    </div>
                  </li>
                  <li>
                    <NavLink exact to="/products" activeClassName="active">
                      products
                    </NavLink>
                  </li>
                  <li>
                    <div className="dropdown_custom">
                      <button>
                        our setup
                        <i className="fa fa-caret-down"></i>
                      </button>
                      <div
                        className={`dropdown_content ${
                          setupDropDown && "active"
                        }`}
                      >
                        <NavLink
                          exact
                          to="/our-setup/infrastructure"
                          activeClassName="active"
                        >
                          infrastructure
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/tech-in-textile"
                          activeClassName="active"
                        >
                          Tech In Textile
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/certifications"
                          activeClassName="active"
                        >
                          certifications
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/quality-and-r+d"
                          activeClassName="active"
                        >
                          quality and r+d
                        </NavLink>
                      </div>
                    </div>
                  </li>
                  <li>
                    <NavLink exact to="/our-sales" activeClassName="active">
                      our sales
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/investor-relations"
                      activeClassName="active"
                    >
                      investors
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/blogs" activeClassName="active">
                      Blogs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/esg" activeClassName="active">
                      esg
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/newsroom" activeClassName="active">
                      newsroom
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/contact-us" activeClassName="active">
                      contact us
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </header>
        )
      ) : notHomePage ? (
        <header className="header mobile_header">
          <div className="wrapper">
            <div className="nav_wrapper">
              <div
                className={`nav_icon ${openMenu ? "open_nav" : ""}`}
                onClick={handleClick}
              >
                <span className="menu"></span>
                <span className="menu"></span>
                <span className="menu"></span>
              </div>
            </div>
            <nav className={openMenu ? "open_menu" : ""}>
              <div className="mobile_menu_wrapper">
                <ul className="bottom_nav">
                  <li>
                    <div className="dropdown_custom">
                      <button onClick={handleAboutMenu}>
                        about us
                        <i className="fa fa-caret-down"></i>
                      </button>
                      <div
                        className={`dropdown_content ${
                          aboutDropDown && "active"
                        }`}
                      >
                        <NavLink
                          exact
                          to="/about-us/the-company"
                          activeClassName="active"
                        >
                          our company
                        </NavLink>
                        <NavLink
                          exact
                          to="/about-us/our-team"
                          activeClassName="active"
                        >
                          Our Team
                        </NavLink>
                        <NavLink
                          exact
                          to="/about-us/our-journey"
                          activeClassName="active"
                        >
                          our journey
                        </NavLink>
                      </div>
                    </div>
                  </li>
                  <li>
                    <NavLink exact to="/products" activeClassName="active">
                      products
                    </NavLink>
                  </li>
                  <li>
                    <div className="dropdown_custom">
                      <button onClick={handleSetupMenu}>
                        our setup
                        <i className="fa fa-caret-down"></i>
                      </button>
                      <div
                        className={`dropdown_content ${
                          setupDropDown && "active"
                        }`}
                      >
                        <NavLink
                          exact
                          to="/our-setup/infrastructure"
                          activeClassName="active"
                        >
                          infrastructure
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/tech-in-textile"
                          activeClassName="active"
                        >
                          Tech In Textile
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/certifications"
                          activeClassName="active"
                        >
                          certifications
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/quality-and-r+d"
                          activeClassName="active"
                        >
                          quality and r+d
                        </NavLink>
                      </div>
                    </div>
                  </li>
                  <li>
                    <NavLink exact to="/our-sales" activeClassName="active">
                      our sales
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/investor-relations"
                      activeClassName="active"
                    >
                      investors
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/blogs" activeClassName="active">
                      Blogs
                    </NavLink>
                  </li>
                  {/* <li>
                      <NavLink exact to="/contact-us" activeClassName="active">
                        contact us
                      </NavLink>
                    </li> */}
                  <li>
                    <NavLink exact to="/esg" activeClassName="active">
                      esg
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/newsroom" activeClassName="active">
                      newsroom
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/contact-us" activeClassName="active">
                      contact us
                    </NavLink>
                  </li>
                </ul>
              </div>
              <ul className="top_nav">
                {/* <li>
                      <NavLink exact to="#" activeClassName="active">
                        virtual tour
                      </NavLink>
                    </li> */}
                {/* <li>
                      <NavLink exact to="/contact-us" activeClassName="active">
                        contact us
                      </NavLink>
                    </li> */}
              </ul>
            </nav>
            <div className="header_link">
              <Link to="/">
                <img src={ColorLogo} alt="Sanathan Textiles Logo" />
              </Link>
            </div>
          </div>
        </header>
      ) : (
        <header
          className={
            colorChange
              ? "changeColor mobile_changeColor"
              : "homepage mobile_homepage"
          }
        >
          <div className="wrapper">
            <div className="nav_wrapper">
              <div
                className={`nav_icon ${openMenu ? "open_nav" : ""}`}
                onClick={handleClick}
              >
                <span className="menu"></span>
                <span className="menu"></span>
                <span className="menu"></span>
              </div>
            </div>
            <nav className={openMenu ? "open_menu" : ""}>
              <ul className="bottom_nav">
                <li>
                  <div className="dropdown_custom">
                    <button onClick={handleAboutMenu}>
                      about us
                      <i className="fa fa-caret-down"></i>
                    </button>
                    <div
                      className={`dropdown_content ${
                        aboutDropDown && "active"
                      }`}
                    >
                      <NavLink
                        exact
                        to="/about-us/the-company"
                        activeClassName="active"
                      >
                        our company
                      </NavLink>
                      <NavLink
                        exact
                        to="/about-us/our-team"
                        activeClassName="active"
                      >
                        Our team
                      </NavLink>
                      <NavLink
                        exact
                        to="/about-us/our-journey"
                        activeClassName="active"
                      >
                        our journey
                      </NavLink>
                    </div>
                  </div>
                </li>
                <li>
                  <NavLink exact to="/products" activeClassName="active">
                    products
                  </NavLink>
                </li>
                <li>
                  <div className="dropdown_custom">
                    <button onClick={handleSetupMenu}>
                      our setup
                      <i className="fa fa-caret-down"></i>
                    </button>
                    <div
                      className={`dropdown_content ${
                        setupDropDown && "active"
                      }`}
                    >
                      <NavLink
                        exact
                        to="/our-setup/infrastructure"
                        activeClassName="active"
                      >
                        infrastructure
                      </NavLink>
                      <NavLink
                        exact
                        to="/our-setup/tech-in-textile"
                        activeClassName="active"
                      >
                        Tech In Textile
                      </NavLink>
                      <NavLink
                        exact
                        to="/our-setup/certifications"
                        activeClassName="active"
                      >
                        certifications
                      </NavLink>
                      <NavLink
                        exact
                        to="/our-setup/quality-and-r+d"
                        activeClassName="active"
                      >
                        quality and r+d
                      </NavLink>
                    </div>
                  </div>
                </li>
                <li>
                  <NavLink exact to="/our-sales" activeClassName="active">
                    our sales
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/investor-relations"
                    activeClassName="active"
                  >
                    investors
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/blogs" activeClassName="active">
                    Blogs
                  </NavLink>
                </li>
                {/* <li>
                      <NavLink exact to="/contact-us" activeClassName="active" >
                        contact us
                      </NavLink>
                    </li> */}
                <li>
                  <NavLink exact to="/esg" activeClassName="active">
                    esg
                  </NavLink>
                </li>{" "}
                <li>
                  <NavLink exact to="/newsroom" activeClassName="active">
                    newsroom
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/contact-us" activeClassName="active">
                    contact us
                  </NavLink>
                </li>
              </ul>
              <ul className="top_nav">
                {/* <li>
                      <NavLink exact to="#" activeClassName="active">
                        virtual tour
                      </NavLink>
                    </li> */}
                {/* <li>
                      <NavLink exact to="/contact-us" activeClassName="active">
                        contact us
                      </NavLink>
                    </li> */}
              </ul>
            </nav>
            <div className="header_link">
              <Link to="/">
                <img
                  src={colorChange ? ColorLogo : WhiteLogo}
                  alt="Sanathan Textiles Logo"
                />
              </Link>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
